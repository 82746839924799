$primaryColor: #3B82F6 !default;
$primaryLightColor: #BFDBFE !default;
$primaryDarkColor: #3B82F6 !default;
$primaryDarkerColor: #3B82F6 !default;
$primaryTextColor: #ffffff !default;

$highlightBg: #EFF6FF !default;
$highlightTextColor: $primaryDarkerColor !default;

@import '../_variables';
@import '../../../../designer/_components';
@import '../_extensions';