.p-toolbar {
  background: $primaryTextColor;
  border: $panelHeaderBorder;
  padding: $panelHeaderPadding;
  border-radius: $borderRadius;
  //height: $panelHeight;

  .p-toolbar-separator {
    margin: 0 $inlineSpacing;
  }
}
.backNavigation-toolbar {
  background: #535c69;
  border: 0px !important;
  border-radius: 0px !important;
  padding: 0.5rem;
  height: 60px !important;
  a {
    color: #ffffff !important;
  }
  .profile-class {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    background: transparent;
    font-size: 18px;
    color: #fff;
    text-align: center;
    line-height: 40px;
    margin-right: 39px;
    border: 1px solid #f2f2f2;
  }
}
