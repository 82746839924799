.formula {
    min-height: 400px;
}



.formula-wrapper .formula {
    background-color: #f2f2f2;
    border: 1px solid #d8d8d8;
    text-align: left;
    box-shadow: 0 4px 12px rgba(0, 0, 0, .25);
    /* min-height: 100%;
    min-width: 100%; */
}

/* .formula-drop {
    margin-top: 14px;
} */

.formula-drop i {
    display: block;
    font-size: 115%;
    margin-bottom: 1em;
}



.formula .formula-item.formula-custom,
.formula-drop .formula-drop-items .formula-custom {
    display: inline-block;
    height: auto;

    line-height: 0.5em;
    padding: 0.6em 1em;
    /* font-weight: 600; */
    border-radius: 2px;
    /* background-color: #f8f8f8; */
    /* border: 1px solid #909090; */
    margin: 0.2em 0 0 0;
    color: #495057;
    text-decoration: none;
    /* box-shadow: 0 2px 6px rgb(0 0 0 / 13%); */
}

.formula .formula-item.formula-custom-operator {
    border-color: #3690ff;
    color: #3690ff;
}

.formula .formula-custom {
    padding: .4em 1em;
    font-size: 85%;
}

.formula-drop .formula-drop-items .formula-custom {
    /* font-size: 115%; */
    font-size: .875rem;
    /* color: #556b82; */
    /* background-color: #dee2e5; */
}

.formula-get {
    margin-top: 160px;
}

.formula-get h4 {
    margin-bottom: 40px;
}

.formula-get .btn {
    box-shadow: 0 4px 6px rgba(0, 0, 0, .25);
}

.formula-get .formula-get-json {
    margin-top: 20px;
    margin-bottom: 160px;
    min-height: 240px;
    background-color: #f8f8f8;
    font-size: 115%;
    padding: 1.6em 2.4em;
    border: 1px solid #d8d8d8;
    position: relative;
    box-shadow: 0 2px 6px rgba(0, 0, 0, .125);
    line-height: 1.6;
    text-align: left;
}

.formula-get .formula-get-json .formula-get-json-label {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 85%;
    padding: .4em 1em;
    background-color: #fafafa;
    color: #505050;
    border-bottom: 1px solid #d8d8d8;
    border-left: 1px solid #d8d8d8;
}

/**Pignose formula**/
.formula-wrapper {
    position: relative;
    /* width: 100%;
    height: 100%; */
}

.formula-wrapper .formula-text {
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    /* color: transparent;
    font-size: 0; */

    width: 100%;
    height: 100%;
    text-indent: -10000px;

}

.formula-container {
    padding: 40px;
    cursor: text;
    position: relative;
    line-height: 2.8;
}

.formula-container .formula-drag {
    display: inline;
    padding: 12px 4px;
    border-radius: 3px;
    vertical-align: middle;
    background-color: rgba(26, 94, 255, .15);
}

.formula-container .formula-cursor {
    display: inline-block;
    vertical-align: middle;
    height: 20px;
    width: 1px;
    background-color: #ababab;
    background-color: rgba(0, 0, 0, .6);
}

.formula-container .formula-unit,
.formula-container .formula-operator,
.formula-container .formula-item {
    display: inline;
    vertical-align: middle;
    padding: 0 10px;
    word-break: break-all;
    margin: 6px;
    vertical-align: middle;
    border-radius: 999px;
    background-color: #ffffff;
    /* -webkit-box-shadow: 0 3px 8px rgba(0, 0, 0, .375);
    -moz-box-shadow: 0 3px 8px rgba(0, 0, 0, .375);
    box-shadow: 0 3px 8px rgba(0, 0, 0, .375);*/
}

.formula-container .formula-item {
    display: inline-block;
    border-radius: 999px;
    /*color: #333333;*/
    height: 24px;
    line-height: 24px;
    position: relative;
    overflow: hidden;
    margin: 0 0.4em !important;
}

.formula-container .formula-item .formula-type {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    min-width: 80px;
    height: 24px;
    line-height: 24px;
    font-size: 13px;
    font-weight: 600;
    word-break: break-all;
    text-align: center;
    -o-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    background-color: #5e636c;
    margin-right: .8em;
    color: #ffffff;
}

.formula-container .formula-operator {
    display: inline-block;
    margin-top: 0;
    margin-bottom: 0;
    width: 24px;
    height: 24px;
    line-height: 24px;
    padding: 0;
    text-align: center;
    font-weight: 600;
    color: #f6474e;
    border-color: #f6474e;
}

.formula-container .formula-bracket {
    background-color: transparent;
    border: 0;
    color: #12c2b7;
}

.formula-alert,
.formula-result {
    position: absolute;
    width: 140px;
    height: 42px;
    line-height: 38px;
    color: #777777;
    box-sizing: border-box;
    -o-box-sizing: border-box;
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    border: 1px solid #d8d8d8;
    border-right: 0;
    border-top: 0;
    background-color: #fafafa;
    border-top: 3px solid #ababab;
    text-align: center;
    top: 0;
    right: 1px;
    z-index: 10;
}

.formula-result {
    right: 150px;
}

.formula-alert.formula-alert-good {
    color: #88ccab;
    border-top-color: #88ccab;
}

.formula-alert.formula-alert-error {
    color: #ff9090;
    border-top-color: #ff9090;
}

.formula-prefix {
    color: rgba(0, 0, 0, .7);
}

.formula-surfix {
    color: rgba(0, 0, 0, .3);
}