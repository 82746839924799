body,
html {
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: var(--fontFamily);
  font-weight: var(--fontWeight);


}


body {
  font-family: var(--font-family);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {

  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}