.p-sidebar {
  background: $overlayContentBg;
  color: $panelContentTextColor;
  border: $overlayContentBorder;
  box-shadow: $overlayContainerShadow;
  //width: 400px !important;
  .p-sidebar-header {
    padding: $panelHeaderPadding;
    justify-content: flex-start;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.03),
      0px 0px 2px rgba(0, 0, 0, 0.06), 0px 2px 6px rgba(0, 0, 0, 0.12) !important;
    margin-bottom: 2rem !important;

    //display: $panelDisplay;
    .p-sidebar-close,
    .p-sidebar-icon {
      @include action-icon();
    }

    & + .p-sidebar-content {
      padding-top: 0;
    }
  }

  .p-sidebar-content {
    padding: $panelContentPadding;
  }
}
#left-menu-sidebar {
  background: rgba(255, 255, 255, 0.97);
  box-shadow: 2px 8px 8px 0 rgb(0 0 0 / 20%);
  transition: background-color 2s ease-out;
  .p-sidebar-header {
    justify-content: flex-start !important;
    padding: 0rem;
    box-shadow: none !important;
    margin-bottom: auto !important;
    height: 60px !important;
    //background-color: var(--bluegray-900) !important;
    //background-color: #eef2f4 !important;
    background-color: #535c69 !important;
    //box-shadow: 2px 8px 8px 0 rgb(0 0 0 / 20%);
    box-shadow: none !important;
  }
  .flex-shrink-0 {
    flex-shrink: 0 !important;
    width: 100%;
    color: #000;
    //margin-left: 4%;
    font-size: 18px;

    /* img {
      padding: 0.5em;
    }
    i {
      padding: 1rem 0.5rem 0 0.5rem;
    }*/
  }
  .p-sidebar-content {
    padding: 0rem;
  }
  // .menu-item-list {
  //   background: rgba(113, 122, 132, 0.09);
  // }
  .menu-item-list .menu-link {
    //background-color: #717a84;
    color: #525c69;
  }
  .menu-item-list .menu-link:hover {
    background-color: #e6eaed;
    color: #000;
  }
}
// .landing-page .landing-page-menu-item:hover {
//   box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
//   transform: translate3d(0, -2px, 0);
//   width: 12rem !important;
//   height: 12rem !important;
//   font-size: 6rem !important;
// }
.landing-page .landing-page-menu-item {
  color: #668690 !important;
}
.landing-page .custom-menu-item {
  color: #668690 !important;
}
.landing-page .custom-disabled-menu {
  pointer-events: none;
  opacity: 0.4;
}
#sidebar-for-add-account {
  width: 65rem;
}
.profileSidebar {
  // width: 30rem !important;
  width: auto !important;
  border-radius: 2rem;
}
.profileSidebar img {
  border-radius: 50%;
  display: block;
  height: auto;
  margin: 20px 20px 20px 16px;
  overflow: hidden;
  width: 100px;
}
.profileSidebar {
  .preference_selection {
    background: rgb(242, 242, 242);
    // width: fit-content;
    margin: 0px auto;
    text-align: center;
    position: relative;
    cursor: pointer;

    .my-profile-preference_selection :hover {
      background: rgb(255, 255, 255);
      // margin: 5px;
      // border-radius: 4px;
      box-shadow: -2px 0 15px rgba(0, 0, 0, 0.14);
      // transition: transform .2s ease;
    }
    .signout-preference_selection :hover {
      background: rgb(255, 255, 255);
      width: 100%;
      // margin: 5px;
      // border-radius: 4px;
      box-shadow: -2px 0 15px rgba(0, 0, 0, 0.14);
      // transition: transform .2s ease;
    }
  }

  .p-sidebar-header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 15px 22px 10px;
    padding: $panelHeaderPadding;
    justify-content: flex-start;
    box-shadow: none !important;
    margin-bottom: 2rem !important;
  }

  .signout-action {
    display: inline-flex;
    align-items: center;
    font-size: 2rem;
    cursor: pointer;
  }
}
.profile-sidebar-mask-classname {
  top: 60px;
  height: auto;
}
