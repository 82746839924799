.p-organizationchart {
  .p-organizationchart-node-content {
    &.p-organizationchart-selectable-node:not(.p-highlight):hover {
      background: $inputListItemHoverBg;
      color: $inputListItemTextHoverColor;
    }

    &.p-highlight {
      background: $highlightBg;
      color: $highlightTextColor;

      .p-node-toggler {
        i {
          color: darken($highlightBg, 25%);
        }
      }
    }
  }

  .p-organizationchart-line-down {
    background: $organizationChartConnectorColor;
  }

  .p-organizationchart-line-left {
    border-right: $panelContentBorder;
    border-color: $organizationChartConnectorColor;
  }

  .p-organizationchart-line-top {
    border-top: $panelContentBorder;
    border-color: $organizationChartConnectorColor;
  }

  .p-organizationchart-node-content {
    border: $panelContentBorder;
    background: $panelContentBg;
    color: $panelContentTextColor;
    padding: $panelContentPadding;
  }

  .p-organizationchart-node-content .p-node-toggler {
    background: inherit;
    color: inherit;
    border-radius: 50%;

    &:focus {
      @include focused();
    }
  }
}
.organizationChart {
  .orgChart {
    text-align: center;
    .org {
      display: flex;
      justify-content: center;
      align-items: center;

      width: 100%;

      overflow-x: auto;
      text-align: center;
      flex-direction: column;
      padding-top: 1rem;
    }
  }
  .icon {
    position: absolute;
    width: 40px;
    height: 40px;
    border-radius: 50%;
    // background: #e9ecef;
    // right: -20px;
    // top: -20px;
    right: -7px;
    top: 6px;
  }
}
