$prefix: "" !default;
$separator: "\\:" !default;

$sm: 576px !default;
$md: 768px !default;
$lg: 992px !default;
$xl: 1200px !default;
$gutter: 0.5rem !default;
$mdleft: 38px !default;
$mdright: 25px !default;
$fieldMargin: 4rem !default;
$fieldLabelMargin: 0.5rem !default;
$helperTextMargin: 0.25rem !default;
$flex: 0 0 auto !default;
$spacer: 1rem !default;
$backgroundColor: rgb(0, 167, 246) !default;
$position: relative !default;
$borderRadius: 50% !default;
$border: rgb(255, 255, 255) solid 4px !default;
$dispalyBloc: block !default;
$widthHeight: 100% !default;
$padding: 3px !default;
$width: 280px !default;
$fontFamily: "PuviLtBold", Roboto, sans-serif;
$fontSize: calc((16 * 0.07142857143) * 1rem);
$Color: rgb(0, 0, 0);
$marginBottom: 7px;
$whiteSpace: nowrap;
$Overflow: hidden;
$textOverflow: ellipsis;
$fontWeight: bold;
$breakpoints: (
  "sm": $sm,
  "md": $md,
  "lg": $lg,
  "xl": $xl,
) !default;

.profile-img-center {
  flex: $flex;
  margin-left: $mdleft;
  margin-right: $mdright;
  height: $fieldMargin;
  width: $fieldMargin;
  position: $position;
  border-radius: $borderRadius;
  background-color: $backgroundColor;
  border: $border;
}

.profile-img {
  display: $dispalyBloc;
  width: $widthHeight;
  height: $widthHeight;
  border-radius: $borderRadius;
}
// @include style-class("profile-img-center", $profile-img-center, true);
.short-user-info {
  width: $width;
  padding-top: $padding;
  flex: $flex;
}

.sign-out-user-name {
  font-family: $fontFamily;
  font-size: $fontSize;
  color: $Color;
  margin-bottom: $marginBottom;
  white-space: $whiteSpace;
  overflow: $Overflow;
  text-overflow: textOverflow;
  width: $widthHeight;
  font-weight: $fontWeight;
}
.sign-out-user-details {
  color: rgba(0, 0, 0, 0.48);
  font-size: calc((12 * 0.07142857143) * 1rem);
  margin-bottom: $marginBottom;
  white-space: nowrap;
  overflow: $Overflow;
  text-overflow: ellipsis;
  width: $widthHeight;
  direction: ltr !important;
}
